import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { PortalCoreModule, AppConfig, FoundationConfig, SessionTimerSitecoreService } from '@caloptima/portal-foundation';
import { PortalUIComponentsModule, UIConfig, TokenInterceptor } from '@caloptima/portal-ui-components';
import { AuthenticationModule } from '@caloptima/authentication';
import { OAuthModule, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { ContactCustomerServiceComponent } from './layout/contact-customer-service/contact-customer-service.component';
import { BusyService } from './services/busy.service';
import { SignalRClientService } from './services/signalr-client.service';
import { RouterHistoryService } from './services/router-history.service';
import { UserModule } from './user/user.module';
import { provideUserIdleConfig  } from 'angular-user-idle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Constants } from './app.constants';
import { Messages } from './app.messages';
import { PortalConfig } from './portal-config';
import { MemberLookupModule } from './member-lookup/member-lookup.module';
import { AppComponentsModule } from './app-components/app-components.module';
import { PortalModule } from './portal/portal.module';
import { ClaimsService } from './services/claims.service';
import { HeaderComponent } from './layout/header/header.component';
import { NavigatorComponent } from './layout/navigation/navigator.component';
import { SubHeaderComponent } from './layout/sub-header/sub-header.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { environment } from '../environments/environment';
import { OverlayPanelModule } from 'primeng/overlaypanel';

export function initConfig(config: AppConfig, constants: Constants) {
  return () => {
    config.loadConfiguration(constants);

    if(environment.production)
      config.load('api/config');    
    else
      config.load('assets/config.json');        
  }
}

export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    ContactCustomerServiceComponent,
    AppComponent,
    HeaderComponent,
    SubHeaderComponent,
    NavigatorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToolbarModule,
    DropdownModule,
    UserModule,
    MemberLookupModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    AppRoutingModule,
    AuthenticationModule,
    PortalCoreModule,
    PortalUIComponentsModule,
    ContextMenuModule,
    AppComponentsModule,
    PortalModule,
    OAuthModule.forRoot(),
    JwtModule.forRoot({
      config: {
        // throwNoTokenError: true,
        // skipWhenExpired: true,
        // ...
        tokenGetter
      }
    }),
  ],
  exports: [

  ],
  providers: [
    Messages,
    Constants,
    HttpClient,
    AppConfig,
    FoundationConfig,
    PortalConfig,
    OAuthService,
    UrlHelperService,
    ConfirmationService,
    MessageService,
    SessionTimerSitecoreService,
    ClaimsService,
    BusyService,
    RouterHistoryService,
    SignalRClientService,
    {
      provide: UIConfig,
      useClass: PortalConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig, Constants],
      multi: true
    },
    provideUserIdleConfig({idle:840, timeout:60, ping:120}),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
