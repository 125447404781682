import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckerService {
    private currentHash = '{{NEW_HASH_PLACEHOLDER}}';
    private poolingHandler = null;

    private refreshCacheSubject$ = new BehaviorSubject<boolean>(false);
    public refreshCacheChanged$ = this.refreshCacheSubject$.asObservable();

    constructor(
      private http: HttpClient
    ) {}

    public initVersionCheck(url, frequency = 1000 * 60 * 60) {
        this.poolingHandler = setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    private checkVersion(url) {
        this.http.get(url + '?t=' + new Date().getTime())
            .pipe(
              first()
            )
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    if (hashChanged) {
                      this.refreshCacheSubject$.next(true);
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    private hasHashChanged(currentHash, newHash) {
        if (!newHash || newHash === '{{NEW_HASH_PLACEHOLDER}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}
